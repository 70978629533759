import moment from 'moment-timezone'
import React, { useEffect, useRef } from 'react'

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)
  useEffect(() => {
    if (didMount.current) {
      func()
    } else didMount.current = true
  }, deps)
}

export const getRowDataByColumn = (data, colName, colNameValue) => {
  for (let i = 0; i < data.length; i++) {
    const element = data[i]
    if (element[colName] == colNameValue) {
      return element
    }
  }
  return
}

export const convertToFormData = (formData) => {
  const submitFormData = new FormData()
  for (const [key, value] of Object.entries(formData)) {
    if (value && value !== '' && value !== null) {
      if (typeof value === 'object' && Array.isArray(value)) {
        value.forEach((x) => {
          submitFormData.append(`${key}[]`, x)
        })
      } else if (typeof value === 'object' && value instanceof moment) {
        submitFormData.append(key, value.format('YYYY-MM-DD'))
      } else {
        submitFormData.append(key, value)
      }
    }
  }
  return submitFormData
}

export const titleCase = (str) => {
  var splitStr = str?.toLowerCase().split(' ')
  if (!splitStr) return
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

export const ThousandSeparator = (number) => {
  return new Intl.NumberFormat().format(number)
}

export const TwoDigitsThousandSeparator = (number) => {
  // return new Intl.NumberFormat().format(number, {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // })
  // Create a new Intl.NumberFormat instance for the en-US locale
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  // Format the number with thousand separators and two decimal points
  return formatter.format(number)
}
