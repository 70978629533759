import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { CFormInput } from '@coreui/react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  TextareaAutosize,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
} from '@mui/material'

import { ThemeProvider, createTheme } from '@mui/material/styles'

import AdapterDateFns from '@mui/lab/AdapterDateFns'

import PickersDay from '@mui/lab/PickersDay'
import { styled } from '@mui/system'
import TimePicker from '@mui/lab/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { CKEditor } from 'ckeditor4-react'
import { DateCalendar } from '@mui/x-date-pickers'
import { startOfDay } from 'date-fns'
import dayjs from 'dayjs'
import { InputAdornment } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { titleCase } from 'src/helpers/functions'

// Define your custom theme
const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'black !important', // Custom color for the selected date circle
            color: '#ffffff !important', // Text color for the selected date
          },
          '&:hover': {
            backgroundColor: '#68c4d3 !important', // Custom hover color for the selected date circle
          },
        },
      },
    },
  },
})

// Custom Picker Day styled component
const CustomPickersDay = styled(PickersDay)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export const FromToDate = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item xs={12} sm={6}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          id={props.fromDateKey}
          name={props.id}
          label={props.fromDateLabel}
          value={props.fromDateValue}
          style={{ width: '100%' }}
          onChange={(val) => {
            props.onChange({ target: { id: props.fromDateKey, value: dayjs(new Date(val)) } })
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          id={props.toDateKey}
          name={props.id}
          label={props.toDateLabel}
          value={props.toDateValue}
          style={{ width: '100%' }}
          onChange={(val) => {
            props.onChange({ target: { id: props.toDateKey, value: dayjs(new Date(val)) } })
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </LocalizationProvider>
  )
}

export const CustomDate = ({ id, label, value, onChange, disabled, format, keepCase }) => {
  if (value && typeof value === 'string') {
    const ymd = value.split('-')
    value = moment(new Date(ymd[0], ymd[1] - 1, ymd[2]))
  }
  if (!value) {
    value = null
  }

  return (
    <ThemeProvider theme={theme}>
      <FormControl sx={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            format={format ? format : 'YYYY-MM-DD'}
            label={keepCase ? label : titleCase(label)}
            id={id}
            name={id}
            value={value}
            disabled={disabled ? true : false}
            onChange={(val) => {
              onChange({ target: { id: id, value: val } })
            }}
            renderDay={(day, _value, DayComponentProps) => (
              <CustomPickersDay {...DayComponentProps} />
            )}
            slotProps={{
              textField: { variant: 'standard', name: id },
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </ThemeProvider>
  )
}

export const CustomDateNoYear = ({ id, label, value, onChange, disabled, format }) => {
  if (value && typeof value === 'string') {
    const ymd = value.split('-')
    value = moment(new Date(ymd[0], ymd[1] - 1, ymd[2]))
  }
  if (!value) {
    value = null
  }
  return (
    <ThemeProvider theme={theme}>
      <FormControl sx={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            views={['month', 'day']} // Only display month and day selectors
            format={format ? format : 'MM-DD'}
            label={titleCase(label)}
            id={id}
            name={id}
            value={value}
            disabled={disabled ? true : false}
            onChange={(val) => {
              onChange({ target: { id: id, value: val } })
            }}
            slotProps={{
              textField: { variant: 'standard', name: id },
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </ThemeProvider>
  )
}

export const CustomDateYearOnly = ({ id, label, value, onChange, disabled, format }) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControl sx={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            views={['year']}
            label={titleCase(label)}
            id={id}
            name={id}
            value={value}
            disabled={disabled ? true : false}
            onChange={(val) => {
              onChange({ target: { id: id, value: val } })
            }}
            slotProps={{
              textField: { variant: 'standard', name: id },
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </ThemeProvider>
  )
}

export const CustomTime = ({ id, label, value, onChange, disabled, format }) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControl sx={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            label={titleCase(label)}
            name={id}
            value={value}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
    </ThemeProvider>
  )
}

export const StaticDateCalendar = ({
  id,
  label,
  value,
  onChange,
  disabled,
  format,
  open,
  handleClose,
  handleAccept,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="date-picker-dialog-title"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'center', // This ensures the dialog is centered vertically
        },
        '& .MuiPaper-root': {
          margin: '0', // Removes default margin around dialog
        },
      }}
    >
      <FormControl sx={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDatePicker
            format={format ? format : 'YYYY-MM-DD'}
            label={titleCase(label)}
            id={id}
            name={id}
            value={value}
            disabled={disabled ? true : false}
            onAccept={handleAccept}
            onClose={onClose}
            onChange={(val) => {
              onChange({ target: { id: id, value: val } })
            }}
            slotProps={{
              textField: { variant: 'standard', name: id },
            }}
            renderDay={(day, _value, DayComponentProps) => (
              <CustomPickersDay {...DayComponentProps} />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </Dialog>
  )
}

export const CustomDateTime = (props) => {
  return (
    <FormControl fullWidth={true}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          variant="inline"
          format="DD/MM/YYYY HH:mm"
          margin="normal"
          id={props.id}
          label={titleCase(props.label)}
          value={props.value}
          style={{ width: '100%', margin: 0 }}
          onChange={(val) => {
            props.onChange({ target: { id: props.id, value: val } })
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disabled={props.disabled ? true : false}
          minDate={props.minDate ? props.minDate : undefined}
          maxDate={props.maxDate ? props.maxDate : undefined}
          inputProps={{ readOnly: true }}
          ampm={false}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export const CustomDateCalendar = (props) => {
  const [values, setValues] = React.useState([startOfDay(new Date())])
  const [value, setValue] = React.useState()
  return (
    <FormControl fullWidth={true}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          onChange={(newValue) => {
            // console.log(newValue)
            setValue(newValue)
          }}
          // value={values}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export const CustomCheckbox = (props) => {
  return (
    <FormControl>
      {props.label}
      <Checkbox
        checked={props.checked}
        id={props.id}
        name={props.id}
        tabIndex={-1}
        onClick={props.onClick}
        disabled={props.disabled === true ? true : false}
      />
    </FormControl>
  )
}

export const InLineCustomCheckbox = (props) => {
  return (
    <FormGroup>
      <FormControlLabel
        style={{ marginTop: 10 }}
        control={
          <Checkbox
            checked={props.checked}
            id={props.id}
            name={props.id}
            onClick={props.onClick}
            disabled={props.disabled === true ? true : false}
          />
        }
        label={props.label}
      />
    </FormGroup>
  )
}

export const CustomInput = ({ id, fieldLabel, value, onChange, keepCase, ...props }) => {
  return (
    <FormControl fullWidth={true} style={{ ...props.style }}>
      <TextField
        id={id}
        name={id}
        label={keepCase ? fieldLabel : titleCase(fieldLabel)}
        variant={props.variant ? props.variant : 'standard'}
        // value={value ?? ''}
        value={value}
        onChange={onChange}
        disabled={props.disabled ? true : false}
        type={props.type ? props.type : 'text'}
        multiline={props.multiline}
        rows={3}
        onWheel={(e) => {
          e.target.blur()
        }}
        InputProps={{
          endAdornment: props.endAdornment && (
            <InputAdornment flip={false} sx={{ flip: false }} position="end">
              {props.endAdornment}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  )
}

export const CustomTextArea = (props) => {
  return (
    <FormControl fullWidth={true}>
      <label htmlFor={props.id}>{props.fieldLabel}</label>
      {/* <InputLabel>{props.fieldLabel}</InputLabel> */}
      <TextareaAutosize
        id={props.id}
        name={props.id}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled ? true : false}
        maxRows={4}
        minRows={2}
      />
    </FormControl>
  )
}

export const CustomFileInput = (props) => {
  return (
    <FormControl fullWidth={true}>
      <CFormInput
        type="file"
        id={props.id}
        name={props.id}
        onChange={props.onChange}
        disabled={props.disabled ? true : false}
      />
    </FormControl>
  )
}

export const CustomToggle = (props) => {
  return (
    <ToggleButtonGroup value={props.value} exclusive size="small" onChange={props.onChange}>
      {props.options.map((v, index) => {
        return (
          <ToggleButton
            key={index}
            value={v.id}
            size="small"
            disabled={v.id == props.value ? true : false}
          >
            {v.label}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}

export const CustomRadio = (props) => {
  return (
    <>
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup
        row
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      >
        {props.options.map((v, index) => {
          return (
            <FormControlLabel key={index} value={v.value} control={<Radio />} label={v.label} />
          )
        })}
      </RadioGroup>
    </>
  )
}

export const CustomTimePicker = (props) => {
  return (
    <TextField
      style={{ width: '100%' }}
      id={props.id}
      name={props.name}
      label={props.fieldLabel}
      type="time"
      value={props.value}
      onChange={props.onChange}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
    />
  )
}

export const CustomCKeditor = (props) => {
  return (
    <FormControl fullWidth={true}>
      <label>{props.fieldLabel}</label>
      <CKEditor
        id={props.id}
        config={{
          height: props.height,
          extraPlugins: 'justify,font,colorbutton',
          toolbarGroups: [
            { name: 'document', groups: ['mode', 'document', 'doctools'] },
            { name: 'clipboard', groups: ['clipboard', 'undo'] },
            { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
            { name: 'forms' },
            '/',
            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
            {
              name: 'paragraph',
              groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
            },
            { name: 'links' },
            { name: 'insert' },
            '/',
            { name: 'styles' }, // 'font and fontsize' -> 'font' plugin
            { name: 'colors' }, // 'colors' -> 'colorbutton' plugin
            { name: 'tools' },
            { name: 'others' },
            { name: 'about' },
          ],
        }}
        initData={props.initData}
        onChange={props.onChange}
      />
    </FormControl>
  )
}

CustomRadio.propTypes = {
  label: PropTypes.node,
  defaultValue: PropTypes.node,
  value: PropTypes.node,
  onChange: PropTypes.func,
  name: PropTypes.node,
  options: PropTypes.array,
}

CustomToggle.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}

FromToDate.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fromDateKey: PropTypes.node,
    fromDateLabel: PropTypes.node,
    fromDateValue: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomDate.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired
CustomDateNoYear.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomDateYearOnly.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomTime.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

StaticDateCalendar.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,

    open: PropTypes.func,
    handleClose: PropTypes.func,
    handleAccept: PropTypes.func,
  }).isRequired,
}).isRequired

CustomDateTime.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
    minDate: PropTypes.any,
  }).isRequired,
}).isRequired

CustomDateCalendar.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
  }).isRequired,
}).isRequired

CustomCheckbox.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    label: PropTypes.node,
    checked: PropTypes.node,
    onClick: PropTypes.node,
  }).isRequired,
}).isRequired

InLineCustomCheckbox.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    label: PropTypes.node,
    checked: PropTypes.node,
    onClick: PropTypes.node,
    disabled: PropTypes.node,
  }).isRequired,
}).isRequired

CustomInput.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomTextArea.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomFileInput.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    onChange: PropTypes.func,
    disabled: PropTypes.any,
  }).isRequired,
}).isRequired

CustomTimePicker.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    name: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.func,
  }).isRequired,
}).isRequired

CustomCKeditor.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    initData: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired
